.flag {
  height: 160px;
  display: block;
  border: 1px solid #ddd;
}

.boxer {
  font-size: 70px;
}

.name {
  font-size: 50px;
  padding-top: 10px;
}