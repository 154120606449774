* {
  font-weight: bold;
}

body {
  background: linear-gradient(to bottom, #513389, #311d55);
  height: 100vh;
}

.bout {
  display: inline-block;
  width: 33%;
}

.ring {
  font-size: 80px;
  font-weight: normal;
  color: #fff;
}

.number {
  font-size: 190px;
  font-weight: normal;
  color: #fff;
  padding-bottom: 40px;
}

.vs {
  color: #DD0934;
  margin: 15px;
  margin-bottom: 20px;
  font-size: 40px;
}

.red {
  color: #fff;
}

.blue {
  color: #fff;
}